.heros {
  max-width: var(--containerSize);
  width: 100%;
  height: calc(100vh - 100px);
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  padding: 0 16px;
}

.hero {
  display: flex;
}

.leftSide {
  flex: 1;
  position: relative;
}

.subTitle {
  font-size: 20px;
  position: absolute;
  top: -15px;
}

.mainTitle {
  font-size: 50px;
  width: 100%;
}

.mainContent {
  font-size: 24px;
}

.content {
  margin-top: 30px;
  font-size: 40px;
}

.date {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: 500;
}

.fullDay {
  color: var(--templateColor);
}

.location {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.icon {
  color: var(--templateColor);
}

.rightSide {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  width: 300px;
  height: 300px;
}

.logoContainer img {
  width: 100%;
  height: 100%;
}

.timerContainer {
  margin-top: 80px;
  display: flex;
  width: 100%;
  background-color: var(--templateColor);
  font-size: 45px;
  font-weight: 600;
  align-items: center;
  padding: 30px;
  border-radius: 20px;
}

.timerDesc {
  flex: 1;
}

.timer {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
}

.times {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1280px) {
  .title {
    font-size: 72px;
  }
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 64px;
  }

  .imgContainer {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column-reverse;
    gap: 64px;
  }
  .subTitle {
    font-size: 18px;
  }

  .mainTitle {
    font-size: 40px;
  }

  .mainContent {
    font-size: 20px;
  }

  .content {
    font-size: 32px;
  }

  .date {
    font-size: 24px;
  }

  .logoContainer {
    width: 230px;
    height: 230px;
  }

  .timerContainer {
    padding: 16px;
    font-size: 36px;
    font-weight: 500;
  }
}

@media screen and (max-width: 640px) {
  .hero {
    gap: 48px;
  }

  .subTitle {
    font-size: 16px;
  }

  .mainTitle {
    font-size: 32px;
  }

  .mainContent {
    font-size: 18px;
  }

  .content {
    font-size: 24px;
  }

  .date {
    font-size: 20px;
  }

  .logoContainer {
    width: 180px;
    height: 180px;
  }

  .timerContainer {
    font-size: 32px;
  }
}

@media screen and (max-width: 520px) {
  .hero {
    gap: 36px;
  }

  .logoContainer {
    width: 160px;
    height: 160px;
  }

  .timerContainer {
    font-size: 24px;
  }
}

@media screen and (max-width: 400px) {
  .hero {
    gap: 32px;
  }

  .logoContainer {
    width: 120px;
    height: 120px;
  }

  .timerContainer {
    font-size: 18px;
  }
}
