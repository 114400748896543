.awardsContainer {
  max-width: var(--containerSize);
  width: 100%;
  color: var(--textColor);
  padding: 64px 16px;
}

.title {
  font-size: var(--PageTitleSize);
  color: var(--templateColor);
  display: inline-block;
  margin-bottom: 50px;
}

.awards {
  display: flex;
  justify-content: space-around;
  gap: 30px;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.awardTitle {
  font-size: 28px;
  text-align: center;
  margin-bottom: 40px;
}

.questionmark {
  font-size: 150px;
  color: var(--templateColor);
}

.questionmark1 {
  font-size: 200px;
  color: var(--templateColor);
}

.award2 > img {
  width: 150px;
}

.award1 > img {
  width: 200px;
}

.award3 > img {
  width: 150px;
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .awards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .award1 {
    order: 1;
  }
  .award2 {
    order: 2;
  }
  .award3 {
    order: 3;
  }

  .awardTitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 640px) {
  .awards {
    gap: 24px;
  }

  .awardTitle {
    font-size: 20px;
  }
}
