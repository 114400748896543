@import url('https://fonts.googleapis.com/css2?family=Tomorrow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --bg: #000028;
  --bgSoft: #fff;
  --textColor: #fff;
  --templateColor: #d5d820;
  --softColor: #aaa7b1;
  --containerSize: 1280px;
  --PageTitleSize: 48px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tomorrow', sans-serif;
}

.App {
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.appContainer {
  width: 100%;
  max-width: var(--containerSize);
}

@media screen and (max-width: 768px) {
  :root {
    --PageTitleSize: 32px;
  }
}
