.container {
  padding: 64px 16px;
}

.title {
  color: var(--templateColor);
  font-size: var(--PageTitleSize);
  margin-bottom: 50px;
}

.sponsors {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.img {
  width: calc(100% / 5 - 32px);
  margin-bottom: 45px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.img img {
  width: 70%;
  height: auto;
}

@media screen and (max-width: 1280px) {
  .img {
    width: calc(100% / 4 - 32px);
  }
}

@media screen and (max-width: 1024px) {
  .img {
    width: calc(100% / 3 - 32px);
  }
}

@media screen and (max-width: 768px) {
  .img {
    width: calc(100% / 3 - 32px);
  }

  .img img {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .img {
    width: calc(100% / 2 - 32px);
  }

  .img img {
    width: 70%;
  }
}

@media screen and (max-width: 460px) {
  .img img {
    width: 100%;
  }
}
