.container {
  max-width: var(--containerSize);
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 64px 16px;
}

.sectionContainer {
  display: flex;
  align-items: center;
}

.sectionTitle {
  display: flex;
  flex-direction: column;
  font-size: var(--PageTitleSize);
  margin: 0 0 30px 0;
  color: var(--templateColor);
}

.sectionButtons {
  display: flex;
  gap: 24px;
  padding-left: 48px;
}

.button {
  cursor: pointer;
  padding: 16px 48px;
  border-radius: 50px;
  border: none;
  font-weight: 500;
}

.header {
  padding: 28px 70px;
  background-color: var(--templateColor);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 24px;
  display: flex;
  gap: 20px;
}

.content {
  padding: 0 70px;
  padding-top: 45px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: var(--bgSoft);
  padding-bottom: 48px;
}

.item {
  display: flex;
}

.time {
  width: 100px;
  font-size: 18px;
}

.detail {
  width: calc(100% - 100px);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 30px 40px;
}

.detail::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 70%;
  background-color: var(--templateColor);
  left: 0;
  top: 16px;
}

.detail::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--templateColor);
  border-radius: 50%;
  left: -4px;
  top: 0%;
}

.title {
  font-size: 18px;
}

.location {
  font-size: 14px;
  padding-top: 16px;
  text-transform: capitalize;
  color: var(--softColor);
}

@media screen and (max-width: 768px) {
  .header {
    padding: 28px 36px;
    font-size: 20px;
  }

  .content {
    padding: 0 36px;
    padding-top: 45px;
  }

  .time {
    width: 80px;
    font-size: 16px;
  }

  .detail {
    width: calc(100% - 80px);
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 30px;
  }
}

@media screen and (max-width: 400px) {
  .header {
    padding: 16px;
    font-size: 20px;
  }

  .content {
    padding: 0 16px;
    padding-top: 45px;
  }

  .time {
    width: 80px;
    font-size: 16px;
  }

  .detail {
    width: calc(100% - 80px);
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 0 16px 24px;
  }
}
