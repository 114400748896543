.tinyContainer {
  max-width: var(--containerSize);
  width: 100%;
  margin: 64px 0;
}

.title {
  color: var(--templateColor);
  font-size: var(--PageTitleSize);
  margin-bottom: 50px;
  padding: 0 16px;
}

iframe {
  width: 100%;
  height: 700px;
}
