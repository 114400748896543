.infosContainer {
  max-width: var(--containerSize);
  width: 100%;
  color: var(--textColor);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 16px;
}

.infosItem {
  display: flex;
  align-items: center;
  gap: 16px;
}

.title {
  color: var(--templateColor);
  font-size: 28px;
  line-height: 40px;
  flex: 1.5;
}

.descr {
  font-size: 20px;
  line-height: 30px;
  flex: 2;
}

hr {
  margin: 50px 0;
  height: 1px;
  background-color: rgba(247, 243, 243, 0.2);
  border: none;
}

a {
  text-decoration: none;
  color: var(--textColor);
}

@media screen and (max-width: 1280px) {
  .title {
    font-size: 24px;
  }

  .descr {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 20px;
  }

  .descr {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .infosItem {
    flex-direction: column;
    text-align: center;
  }

  .title {
    font-size: 18px;
  }

  .descr {
    font-size: 14px;
  }
}
