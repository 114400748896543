.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--softColor);
  padding: 0 16px;
  padding-bottom: 16px;
}

ul {
  list-style-type: none;
}

.footer-credits {
  max-width: calc(var(--containerSize) - 32px);
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--softColor);
  padding-top: 20px;
}

.left-side {
  display: flex;
}

.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-side > li {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-weight: 500;
}

.icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 20px;
  color: var(--softColor);
  transition: 0.3s all ease-in-out;
}

.icon:hover {
  transform: scale(1.09);
}
.languages {
  margin-right: 10px;
}

@media screen and (max-width: 520px) {
  .footer-credits {
    flex-direction: column-reverse;
    gap: 16px;
    align-items: center;
  }
}
